import { Controller } from "@hotwired/stimulus";

function dragover(event) {
  event.preventDefault();

  event.currentTarget.classList.remove("bg-onyx-50");
  event.currentTarget.classList.add("bg-sky-50");
}

function dragleave(event) {
  event.preventDefault();

  event.currentTarget.classList.remove("bg-sky-50");
  event.currentTarget.classList.add("bg-onyx-50");
}

// Connects to data-controller="file-upload"
export default class extends Controller {
  static targets = ["input", "preview"];

  connect() {
    this.dragover = dragover.bind(this);
    this.dragleave = dragleave.bind(this);
  }

  previewFile(event) {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.previewTarget.src = e.target.result;
        this.previewTarget.classList.remove("hidden");
      };

      reader.readAsDataURL(file);
    }
  }

  triggerFileInput() {
    this.inputTarget.click();
  }

  drop(event) {
    event.preventDefault();

    event.currentTarget.classList.remove("bg-sky-50");
    event.currentTarget.classList.add("bg-onyx-50");

    const file = event.dataTransfer.files[0];

    if (file) {
      this.inputTarget.files = event.dataTransfer.files;
      this.previewFile({ target: this.inputTarget });
    }
  }
}
