import { Controller } from "@hotwired/stimulus";

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["#refreshCounter"] }] */

export default class extends Controller {
  static targets = ["checkbox", "statesCounter", "zipsInput", "zipsCounter", "specialtyCheckbox", "specialtiesCounter"];

  connect() {
    this.sortItems("state");
    this.sortItems("specialty");
    this.refreshStatesCounter();
    this.refreshZipsCounter();
    this.refreshSpecialtiesCounter();
    this.initializeClickOutside();
    this.setupZipCodeFiltering();

    this.element.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      checkbox.addEventListener("change", (event) => {
        const listItem = event.target.closest("li");

        if (listItem.classList.contains("state-name-list-item")) {
          this.clearAndFocusSearchInput("state");
          this.sortItems("state");
        } else {
          this.clearAndFocusSearchInput("specialty");
          this.sortItems("specialty");
        }
      });
    });
  }

  setupZipCodeFiltering() {
    const zipInput = this.element.querySelector('input[name="zipcode"]');
    if (!zipInput) return;

    const hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.name = "zipcode";
    hiddenInput.value = zipInput.value;
    zipInput.parentNode.appendChild(hiddenInput);

    zipInput.value = "";
    zipInput.removeAttribute("name");

    const accordionBody = zipInput.closest(".accordion-body");
    if (!accordionBody) return;

    let zipList = accordionBody.querySelector(".zip-code-list");

    if (!zipList) {
      zipList = document.createElement("ul");
      zipList.className = "zip-code-list mt-4";
      accordionBody.appendChild(zipList);
    }

    if (hiddenInput.value) {
      const initialZips = hiddenInput.value
        .split(",")
        .map((zip) => zip.trim())
        .filter(Boolean);

      zipList.innerHTML = "";

      initialZips.forEach((zip) => this.addZipCodeCheckbox(zip, zipList, hiddenInput));
    }

    zipInput.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        this.processZipCodes(zipInput, zipList, hiddenInput);
      }
    });
  }

  processZipCodes(zipInput, zipList, hiddenInput) {
    if (!zipInput.value.trim()) return;

    const newZips = zipInput.value
      .split(",")
      .map((zip) => zip.trim())
      .filter(Boolean);

    newZips.forEach((zip) => {
      this.addZipCodeCheckbox(zip, zipList, hiddenInput);
    });

    const newZipInput = zipInput;
    newZipInput.value = "";
  }

  addZipCodeCheckbox(zip, zipList, hiddenInput) {
    if (zipList.querySelector(`[data-zip="${zip}"]`)) {
      return;
    }

    const template = document.getElementById("zip-checkbox-template");
    if (!template) return;

    const li = template.content.firstElementChild.cloneNode(true);

    li.dataset.zip = zip;

    const checkbox = li.querySelector('input[type="checkbox"]');
    const label = li.querySelector("label");
    const span = li.querySelector("span");
    const containerDiv = li.querySelector("div");
    const checkboxId = `checkbox-zip-${zip}`;

    checkbox.id = checkboxId;
    label.setAttribute("for", checkboxId);
    span.textContent = zip;

    const handleRemove = (event) => {
      event.stopPropagation();

      li.remove();

      this.updateZipInput(zipList, hiddenInput);
    };

    containerDiv.addEventListener("click", handleRemove);

    this.insertSorted(zipList, li);
    this.updateZipInput(zipList, hiddenInput);
  }

  /* eslint-disable class-methods-use-this */
  insertSorted(zipList, newLi) {
    const newZip = parseInt(newLi.dataset.zip, 10);
    const items = Array.from(zipList.children);
    const insertIndex = items.findIndex((item) => {
      const currentZip = parseInt(item.dataset.zip, 10);
      return currentZip > newZip;
    });

    if (insertIndex === -1) {
      zipList.appendChild(newLi);
    } else {
      zipList.insertBefore(newLi, items[insertIndex]);
    }
  }

  updateZipInput(zipList, hiddenInput) {
    const zips = Array.from(zipList.querySelectorAll("li"))
      .map((li) => li.dataset.zip)
      .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
      .join(",");

    const newHiddenInput = hiddenInput;
    newHiddenInput.value = zips;

    const sortedList = Array.from(zipList.querySelectorAll("li")).sort(
      (a, b) => parseInt(a.dataset.zip, 10) - parseInt(b.dataset.zip, 10),
    );

    const newZipList = zipList;
    newZipList.innerHTML = "";
    sortedList.forEach((li) => zipList.appendChild(li));
  }
  /* eslint-enable class-methods-use-this */

  clearAndFocusSearchInput(type) {
    const searchInput = document.querySelector(`#${type}Search`);

    if (searchInput) {
      searchInput.value = "";

      setTimeout(() => {
        searchInput.focus();
      }, 100);
    }

    const listItems = this.element.querySelectorAll(`.${type}-name-list-item`);

    listItems.forEach((item) => {
      item.classList.remove("hidden");
    });
  }

  search(inputEvent, type) {
    const searchValue = inputEvent.currentTarget.value.toLowerCase();
    const listItems = this.element.querySelectorAll(`.${type}-name-list-item`);

    listItems.forEach((item) => {
      const labelName = item.querySelector(`.${type}-label-name`).textContent.toLowerCase();

      if (!labelName.includes(searchValue)) {
        item.classList.add("hidden");
      } else {
        item.classList.remove("hidden");
      }
    });
  }

  searchStates(inputEvent) {
    this.search(inputEvent, "state");
  }

  searchSpecialties(inputEvent) {
    this.search(inputEvent, "specialty");
  }

  selectFromLabel(clickEvent) {
    const targetId = CSS.escape(clickEvent.currentTarget.dataset.target);
    const element = this.element.querySelector(`#${targetId}`);

    element.click();
  }

  selectStateFromLabel(clickEvent) {
    this.selectFromLabel(clickEvent);
  }

  selectSpecialtyFromLabel(clickEvent) {
    this.selectFromLabel(clickEvent);
  }

  sortItems(type) {
    const listItem = this.element.querySelector(`.${type}-name-list-item`);
    if (!listItem) return;

    const itemsList = listItem.parentElement;
    const searchItem = itemsList.querySelector("li:first-child");
    const items = Array.from(itemsList.querySelectorAll(`.${type}-name-list-item`));

    const sortedItems = items.sort((a, b) => {
      const aChecked = a.querySelector('input[type="checkbox"]').checked;
      const bChecked = b.querySelector('input[type="checkbox"]').checked;

      if (aChecked === bChecked) {
        const aText = a.querySelector(`.${type}-label-name`).textContent;
        const bText = b.querySelector(`.${type}-label-name`).textContent;
        return aText.localeCompare(bText);
      }

      return aChecked ? -1 : 1;
    });

    while (itemsList.lastChild) {
      itemsList.removeChild(itemsList.lastChild);
    }

    itemsList.appendChild(searchItem);

    sortedItems.forEach((item) => itemsList.appendChild(item));
  }

  initializeClickOutside() {
    document.addEventListener("click", (event) => {
      if (event.target.closest(".zip-code-list")) {
        return;
      }

      const accordionBodies = this.element.querySelectorAll(".accordion-body");

      accordionBodies.forEach((body) => {
        if (body.classList.contains("hidden")) return;

        const header = document.getElementById(body.getAttribute("aria-labelledby"));
        const clickedInsideBody = body.contains(event.target);
        const clickedHeader = header.contains(event.target);

        if (!clickedInsideBody && !clickedHeader) {
          const button = header.querySelector("button[data-accordion-target]");

          if (button) {
            button.click();
          }
        }
      });
    });
  }

  refreshStatesCounter() {
    const count = this.checkboxTargets.filter((checkbox) => checkbox.checked).length;
    this.#refreshCounter(this.statesCounterTarget, count);
  }

  refreshZipsCounter() {
    const count = this.zipsInputTarget.value.split(",").filter((zip) => zip).length;
    this.#refreshCounter(this.zipsCounterTarget, count);
  }

  refreshSpecialtiesCounter() {
    const count = this.specialtyCheckboxTargets.filter((checkbox) => checkbox.checked).length;
    this.#refreshCounter(this.specialtiesCounterTarget, count);
  }

  #refreshCounter(cnt, value) {
    const counter = cnt;
    counter.classList.toggle("hidden", value === 0);
    counter.querySelector("div:first-child").textContent = value.toString();
  }
}
