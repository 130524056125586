import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
    token: String,
  };

  static targets = ["select"];

  connect() {
    this.configureActivepieces();
  }

  configureActivepieces() {
    activepieces.configure({
      prefix: "/workflow",
      instanceUrl: this.urlValue,
      jwtToken: this.tokenValue,
      embedding: {
        containerId: "preview-container",
        builder: {
          disableNavigation: true,
          hideLogo: true,
          hideFlowName: false,
        },
        dashboard: {
          hideSidebar: true,
        },
        hideFolders: true,
      },
    });
  }

  loadFlowFromEdit() {
    if (window.location.href.includes("edit")) {
      this.displayFlowPreview();
      this.navigateToFlow(this.selectTarget.value);
    }
  }

  select() {
    this.displayFlowPreview();
    const selectedFlowId = this.selectTarget.value;
    this.navigateToFlow(selectedFlowId);
  }

  // eslint-disable-next-line class-methods-use-this
  navigateToFlow(flowId) {
    activepieces.navigate({ route: `/flows/${flowId}` });
  }

  // eslint-disable-next-line class-methods-use-this
  hideFlowPreview() {
    document.getElementById("preview-container").style.display = "none";
  }

  // eslint-disable-next-line class-methods-use-this
  displayFlowPreview() {
    document.getElementById("preview-container").style.display = "block";
  }
}
