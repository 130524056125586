import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

// Connects to data-controller="import-status-filter"
export default class extends Controller {
  connect() {
    const slimSelect = new SlimSelect({
      select: this.element,
      settings: {
        openPosition: "down",
        placeholderText: "Status",
      },
    });

    return slimSelect;
  }
}
