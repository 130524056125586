import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  connect() {
    const slimSelect = new SlimSelect({
      select: this.element,
      settings: {
        openPosition: "down",
        placeholderText: this.data.get("placeholder"),
      },
    });

    return slimSelect;
  }
}
