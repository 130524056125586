import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["query", "filterable"];

  filter() {
    const lowerCaseFilterTerm = this.queryTarget.value.toLowerCase();

    this.filterableTargets.forEach((el) => {
      const filterableValues = el.getAttribute("data-filter-values").toLowerCase();
      const visible = filterableValues.includes(lowerCaseFilterTerm);

      el.classList.toggle("hidden", !visible);
    });
  }
}
