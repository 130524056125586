import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="tab-navigation"
export default class extends Controller {
  static targets = ["tab", "panel"];

  connect() {
    this.showTab(0);
  }

  changeTab(event) {
    event.preventDefault();

    const clickedTab = event.currentTarget;
    const tabIndex = parseInt(clickedTab.querySelector("a").dataset.tabIndex, 10);

    this.showTab(tabIndex);
  }

  back(event) {
    event.preventDefault();

    const currentIndex = this.getCurrentTabIndex();

    if (currentIndex > 0) {
      this.showTab(currentIndex - 1);
    }
  }

  next(event) {
    event.preventDefault();

    const currentIndex = this.getCurrentTabIndex();

    if (currentIndex < this.tabTargets.length - 1) {
      this.showTab(currentIndex + 1);
    }
  }

  showTab(index) {
    this.tabTargets.forEach((tab, i) => {
      const link = tab.querySelector("a");

      if (i === index) {
        link.classList.remove("text-onyx-500", "hover:text-onyx-700");
        link.classList.add(
          "border-midnight-950",
          "text-midnight-950",
          "underline",
          "underline-offset-[0.8rem]",
          "decoration-1",
          "decoration-midnight-950",
        );
      } else {
        link.classList.remove(
          "border-midnight-950",
          "text-midnight-950",
          "underline",
          "underline-offset-[0.8rem]",
          "decoration-1",
          "decoration-midnight-950",
        );

        link.classList.add("text-onyx-500", "hover:text-onyx-700");
      }
    });

    this.panelTargets.forEach((panel, i) => {
      if (i === index) {
        panel.classList.remove("hidden");
      } else {
        panel.classList.add("hidden");
      }
    });
  }

  getCurrentTabIndex() {
    return this.panelTargets.findIndex((panel) => !panel.classList.contains("hidden"));
  }
}
