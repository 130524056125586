import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  update() {
    const selectValue = this.element.options[this.element.selectedIndex].value || "0?";
    const frame = document.getElementById("segment_preview");

    frame.src = `/providers/segment_preview/${selectValue}`;
  }
}
