import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["active"];

  static values = {
    url: String,
    id: Number,
  };

  toggle() {
    const url = this.urlValue;
    const fullUrl = `${url}/${this.idValue}`;
    const workflowObject = {
      workflow: {
        active: !this.activeTarget.checked,
      },
    };

    fetch(fullUrl, {
      body: JSON.stringify(workflowObject),
      method: "PATCH",
      dataType: "script",
      credentials: "include",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        "Content-Type": "application/json",
      },
    });
  }
}
