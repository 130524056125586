import { Turbo } from "@hotwired/turbo-rails";

const {
  navigator: {
    delegate: { adapter },
  },
} = Turbo;
const { progressBar } = adapter;
const { session } = Turbo;

let progressBarTimeout = null;

document.addEventListener("turbo:before-fetch-request", ({ target }) => {
  if (!(target instanceof HTMLElement) || target.getAttribute("data-turbo-progress-bar") !== "true") {
    return;
  }

  if (!progressBarTimeout) {
    progressBar.setValue(0);
  }

  progressBarTimeout = window.setTimeout(() => progressBar.show(), session.progressBarDelay);
});

document.addEventListener("turbo:before-fetch-response", () => {
  if (progressBarTimeout) {
    window.clearTimeout(progressBarTimeout);
    progressBar.hide();
    progressBarTimeout = null;
  }
});
