import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  update() {
    const frame = document.getElementById("event_preview");
    const selectValue = this.element.options[this.element.selectedIndex].value || "0?";

    frame.src = `/workflow/event_preview/${selectValue}`;
  }
}
