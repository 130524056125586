[
  "Legal Name",
  "DBA Name",
  "Group Npi",
  "Tin",
  "Group Medicaid #",
  "Group Medicare #",
  "Remit Address Street 1",
  "Remit Address Street 2",
  "Remit City",
  "Remit County",
  "Remit State",
  "Remit Zip",
  "Mailing Address Street 1",
  "Mailing Address Street 2",
  "Mailing City",
  "Mailing County",
  "Mailing State",
  "Mailing Zip",
  "Practitioner Last Name",
  "Practitioner First Name",
  "Suffix",
  "Degree",
  "Practitioner Npi",
  "Individual Medicaid #",
  "Individual Medicare #",
  "Caqh",
  "Taxonomy Code",
  "Primary Specialty",
  "Additional Specialty",
  "Pcp Or Sp",
  "Practitioner Gender",
  "Practitioner Race/Ethnicity",
  "Languages Spoken",
  "Practice Address Street 1",
  "Practice Address Street 2",
  "Practice City",
  "Practice State",
  "Practice Zip",
  "Practice County",
  "Region",
  "Phone",
  "Fax",
  "Prac. Hrs - Mon",
  "Prac. Hrs - Tues",
  "Prac. Hrs - Weds",
  "Prac. Hrs - Thurs",
  "Prac. Hrs - Fri",
  "Prac. Hrs - Sat",
  "Prac. Hrs - Sun",
  "Handicap Accessible (Y/N)",
  "Accepting New Patients (Y/N)",
  "Gender Restrictions",
  "Min Age",
  "Max Age",
  "Telemedicine Services Provided",
  "Contact Name",
  "Contact Email",
  "Contact Phone",
  "Contact Fax"
]
