import { Controller } from "@hotwired/stimulus";
import papaparse from "papaparse";
import AVAILABLE_HEADER_MAPPINGS from "../data/available_header_mappings.json";

const csvContentToHeaderMapper = (csvContent) => {
  const headers = csvContent[0];
  const previewContent = csvContent[1];
  const initialMapping = {};

  headers.forEach((header, index) => {
    const parsedHeader = header.trim();
    const preview = previewContent[index].trim();
    const columnMapping =
      window.formerHeaderMappings && window.formerHeaderMappings[parsedHeader]
        ? window.formerHeaderMappings[parsedHeader]
        : parsedHeader;
    initialMapping[parsedHeader] = {
      raw_file_header: parsedHeader,
      cell_preview: preview,
      column_mapping: columnMapping,
    };
  });

  const table = document.createElement("table");
  const thead = document.createElement("thead");
  const tbody = document.createElement("tbody");

  thead.classList.add("bg-onyx-50");

  // Create table header
  const headerRow = document.createElement("tr");
  const tableHeaders = ["CSV Header Row", "Row Preview", "Header Mapping"];
  tableHeaders.forEach((header) => {
    const th = document.createElement("th");
    th.textContent = header;
    headerRow.appendChild(th);
    th.classList.add("text-xs", "text-onyx-500", "font-semibold", "uppercase", "leading-4", "px-6", "py-3");
  });
  thead.appendChild(headerRow);

  // Create table body
  const mappingValues = Object.values(initialMapping);
  mappingValues.forEach((mapping) => {
    const row = document.createElement("tr");
    row.classList.add("bg-white", "dark:bg-gray-800", "border-b", "last:border-b-0");
    Object.values(mapping).forEach((value, index) => {
      const td = document.createElement("td");
      td.classList.add("px-6", "py-3", "text-onyx-950", "dark:text-gray-400");

      // if it is the last cell in the row, add a select dropdown
      if (index === Object.values(mapping).length - 1) {
        const select = document.createElement("select");
        select.classList.add(
          "w-full",
          "bg-white",
          "rounded-lg",
          "border",
          "border-onyx-300",
          "h-9",
          "px-2.5",
          "text-onyx-500",
        );

        // Add options to the select dropdown
        AVAILABLE_HEADER_MAPPINGS.forEach((header) => {
          const option = document.createElement("option");
          option.value = header;
          option.textContent = header;
          select.appendChild(option);
        });
        select.value = value;

        td.appendChild(select);
      } else {
        td.textContent = value;
      }
      row.appendChild(td);
    });
    tbody.appendChild(row);
  });

  table.appendChild(thead);
  table.classList.add("table-fixed", "w-full", "text-left", "rounded-lg");
  table.appendChild(tbody);

  const mappingFieldsForm = document.querySelector("#mapping-fields-form");
  mappingFieldsForm.innerHTML = "";
  mappingFieldsForm.appendChild(table);
  return initialMapping;
};

const processFile = (file) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const csvData = event.target.result;
    papaparse.parse(csvData, {
      complete: (results) => {
        const csvContent = results.data;
        csvContentToHeaderMapper(csvContent);
      },
    });
  };
  reader.readAsText(file);
};

const onFileUpload = (event) => {
  const { files } = event.target;
  if (files.length > 0) {
    processFile(files[0]);
  }
};

// Connects to data-controller="import-file-uploader"
export default class extends Controller {
  connect() {
    const fileInput = this.element.querySelector("#file-upload");
    fileInput.addEventListener("change", onFileUpload);
    const createImportButton = document.querySelector("#create-import");

    const handleCreateImport = (e) => {
      e.preventDefault();
      const mappingFields = document.querySelector("#mapping-fields");
      const tableRows = document.querySelectorAll("#mapping-fields-form tbody tr");
      const mapping = {};

      tableRows.forEach((row) => {
        const cells = row.querySelectorAll("td");
        const rawFileHeader = cells[0].textContent;
        const columnMapping = cells[2].querySelector("select").value;
        mapping[rawFileHeader] = columnMapping;
      });

      mappingFields.value = JSON.stringify(mapping);
      e.target.closest("form").submit();
    };

    createImportButton.addEventListener("click", handleCreateImport);
  }
}
